<template>
    <div>
        <el-input
            v-model="value"
            :placeholder="params.name"
            :disabled="disabled"
            :readonly="params.readonly || false"
            @focus='$event.target.blur()'
            @clear="clear"
            clearable>
            <el-button
                slot="append"
                icon="el-icon-search"
                @click="show"
                :disabled="disabled"
            ></el-button>
        </el-input>
        <advance-multi-dialog
            ref="advanceMultiDialog"
            @reset="reset"
            @confirm="detaliSearchSure"
            @getCheckedList="detailCheckedList">
            <template slot="serach-suffix">
                <slot name="serach-suffix"></slot>
            </template>
        </advance-multi-dialog>
    </div>
</template>

<script>
import advanceMultiDialog from '../otpAdvanceMultiDialog';

export default {
    components: { advanceMultiDialog },
    props: {
        params: Object, // 传入配置参数
        mValue: { // 传入绑定值
            type: [Array, String],
            default() {
                return [];
            }
        },
        disabled: { // 是否启动组件
            type: Boolean,
            default: false
        },
        formData: { // 弹窗表格查询时候，添加查询参数
            type: Object,
            default() {
                return {};
            }
        }
    },
    data() {
        return {
            value: '', // 显示值
            displayColName: '', // 确定显示在输入框中的是哪一列的字段值，不传即默认为配置中value值
            checkedList: [] // 弹窗关闭时候获得已选列表
        };
    },
    methods: {
        /**
         * @description: 获取显示在输入框中的是哪一列的字段值
         */
        getDisplayColName() {
            this.params.tableConfig.forEach((item) => {
                if (item.displayName) {
                    for (const cbItem of this.params.cbParams) {
                        let { 0: sourceProp, 1: destProp } = cbItem.split('#');
                        destProp = destProp || sourceProp;
                        if (sourceProp === item.prop) this.displayColName = destProp;
                    }
                }
            });
            if (this.displayColName === '') this.displayColName = this.params.value;
        },
        /**
         * @description: 格式化显示值
         * @param {array} val 字符串数组 或 对象数组
         * @param {string} val 字符串
         */
        handleDisplay(val) {
            const displayVal = [];
            if (Array.isArray(val)) {
                val.forEach((item) => {
                    const replaceName = this.params.replaceName;
                    const paramsKey = replaceName ? this.displayColName.replace(replaceName, '') : this.displayColName;
                    displayVal.push(typeof item === 'object' ? item[paramsKey] : item);
                });
                this.value = displayVal.join('；');
            } else {
                this.value = val;
            }
        },
        /**
         * @description: 初始化弹窗组件
         */
        show() {
            const current = this.params;
            const advanceCascade = {};
            if (current.advanceCascade && Object.prototype.toString.call(current.advanceCascade) === '[object String]') {
                let advanceArray = [];
                advanceArray = current.advanceCascade.split(',');
                advanceArray.map((item) => {
                    let { 0: sourceProp, 1: destProp } = item.split('#');
                    destProp = destProp || sourceProp;
                    advanceCascade[destProp] = this.formData[sourceProp];
                });
            }
            this.$refs.advanceMultiDialog.show(true, current, advanceCascade, this.checkedList.length > 0 ? this.checkedList : undefined);
        },
        /**
         * @description: 弹窗确认回调函数（点击确认时触发）
         * @param {array} data 确认回调接收的对象数组
         */
        detaliSearchSure(data) {
            this.value = '';
            this.handleDisplay(data);
            this.$emit('getAdvanceValue', data);
        },
        /**
         * @description: 弹窗已选列表回调函数（点击确认时触发）
         * @param {array} data 已选列表回调接收的对象数组
         */
        detailCheckedList(data) {
            this.checkedList = data;
        },
        /**
         * @description: 点击清除按钮操作
         */
        clear() {
            this.checkedList = [];
            this.$refs.advanceMultiDialog.clearAll();
            this.detaliSearchSure([]);
            this.$emit('clear', []);
        },
        /**
         * @description: 重置事件抛出
         */
        reset() {
            this.$emit('reset');
        }
    },
    watch: {
        mValue: {
            handler(newVal, oldVal) {
                if (!newVal || newVal.length === 0) {
                    this.displayName = '';
                    this.value = '';
                    return;
                }
                if (newVal && newVal.length > 0) this.handleDisplay(newVal);
            },
            deep: true
        }
    },
    mounted() {
        this.getDisplayColName();
        if (this.mValue.length > 0) this.handleDisplay(this.mValue);
    }
};
</script>

<style>
</style>
