<template>
    <div>
        <el-input
            v-model="value"
            size="mini"
            :placeholder="config.name"
            :disabled="disabled"
            @keyup.enter.native="show"
            @change="handleChange"
            @clear="clear"
            clearable>
            <el-button
                slot="append"
                icon="el-icon-search"
                class="search-btn"
                @click="show"
                :disabled="disabled"
            ></el-button>
        </el-input>
        <advance-dialog ref="advanceDialog" @confirm="detaliSearchSure"></advance-dialog>
    </div>
</template>

<script>
import _ from 'lodash';
import advanceDialog from '@/components/lots/otpAdvanceDialog';
/**
 * @module lots/LotsMdInput
 * @desc 高级弹窗组件的二次封装
 * @vue-prop {Object} config - 高级弹窗的配置对象
 * @vue-prop {String} label - 显示在输入框内部的文本属性
 * @vue-prop {Boolean} [disabled=false] - 禁用标志
 * @vue-prop {Object} cascadeData - 级联对象
 * @vue-data {String} value - 组件的值，存放props.label
 * @vue-event {Object} change - 更改值时向外层传值
 */
export default {
    name: 'LotsMdInput',
    components: { advanceDialog },
    props: {
        config: Object,
        label: [String, Number],
        disabled: {
            type: Boolean,
            default: false
        },
        cascadeData: {
            type: Object,
            default() {
                return {};
            }
        }
    },
    data() {
        return {
            value: ''
        };
    },
    created() {
        this.init();
        this.value = this.label;
    },
    watch: {
        label(val) {
            this.value = val;
        }
    },
    methods: {
        init() {
            // 初始化空的返回对象
            this.defaultCbObj = {};
            _.forEach(this.config.cbParams, item => {
                if (!item) return;
                const arr = item.split('#');
                this.defaultCbObj[arr.pop()] = '';
            });
        },
        show() {
            const config = this.config;
            const advanceCascade = {};
            if (_.isString(config.advanceCascade)) {
                let advanceArray = [];
                advanceArray = config.advanceCascade.split(',');
                const noSourceList = [];
                advanceArray.map((item) => {
                    let { 0: sourceProp, 1: destProp } = item.split('#');
                    destProp = destProp || sourceProp;
                    advanceCascade[destProp] = _.get(this.cascadeData, sourceProp, '');
                    if (!_.get(this.cascadeData, sourceProp, '')) {
                        noSourceList.push(sourceProp);
                    }
                });
                noSourceList.length && this.$emit('unfindableField', noSourceList);
                if (noSourceList.length && config.checkFailedPrevent) {
                    return false;
                }
            }
            this.$refs.advanceDialog.show(true, config, advanceCascade, this.value);
        },
        handleChange() {
            // this.value = '';
            this.clear();
        },
        detaliSearchSure(data) {
            const prop = this.config.prop || this.config.value;
            this.value = _.get(data, prop, '');
            this.$emit('change', data);
        },
        clear() {
            this.$emit('change', this.defaultCbObj);
        }
    }
};
</script>
<style lang="less" scoped>
.search-btn {
    padding: 12px 7px;
}
</style>
