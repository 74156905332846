<template>
    <el-select :value="value" size="mini" @input="$emit('input', $event)" v-bind="$attrs" v-on="$listeners">
        <el-option
            v-for="item in newOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value">
        </el-option>
  </el-select>
</template>
<script>
import _ from 'lodash';
import { dictWithUrl } from '../api';
/**
 * @module lots/lotsSelect
 * @desc 下拉框组件的二次封装
 * @vue-prop {String} value - 下拉框的值
 * @vue-prop {String} optionsKey - 数据字典的值
 * @vue-prop {Array.<Object>} options - 下拉框选项，与optionsKey二选一配置
 * @vue-prop {String} url - 数据字典接口的前缀
 * @vue-data {Array} newOptions - 对传入的下拉框选项进行处理后重新赋值
 */
export default {
    name: 'lotsSelect',
    inheritAttrs: false,
    props: ['value', 'optionsKey', 'options', 'url'],
    data() {
        return {
            newOptions: null
        };
    },
    watch: {
        async optionsKey(newKey) {
            if (newKey) {
                let options = {};
                const { data: maps } = await dictWithUrl(this.optionsKey, this.url);
                options = _.chain(maps).get(this.optionsKey, {}).value();
                this.newOptions = _.chain(options).entries().map(arr => ({ value: arr[0], label: arr[1] })).sortBy(item => item.value).value();
            }
        },
        options(newVal) {
            if (!_.isEmpty(newVal)) {
                this.newOptions = _.chain(newVal).entries().map(arr => ({ value: arr[0], label: arr[1] })).sortBy(item => item.value).value();
            }
        }
    },
    async mounted() {
        let options = {};
        if (this.optionsKey) {
            const { data: maps } = await dictWithUrl(this.optionsKey, this.url);
            options = _.chain(maps).get(this.optionsKey, {}).value();
        } else if (this.options) {
            options = this.options;
        }
        this.newOptions = _.chain(options).entries().map(arr => ({ value: arr[0], label: arr[1] })).sortBy(item => item.value).value();
    }
};
</script>
