<template>
    <el-table class="table-box" ref="elTable"
        :data="newRows" border style="width: 100%" tooltip-effect="dark" :row-class-name="rowClassHandler"
        v-bind="attrsExt" v-on="$listeners">
        <el-table-column v-if="selection" type="selection" fixed="left" width="40" align="center" :selectable="selectable"></el-table-column>
        <el-table-column v-if="index" type="index" :index="1" width="40"></el-table-column>
        <!-- <template v-for="(col, cIndex) in activeColumns"> -->
        <el-table-column
            v-for="(col, cIndex) in activeColumns"
            :key="col.prop + cIndex"
            :label="$translate(col.label)"
            :show-overflow-tooltip="_.get(col, 'showTooltip', true)"
            :sortable="_.get(col, 'sort', false)"
            v-bind="{ ...col, type: _.get(col, 'columnType', '') }">
            <template v-slot:header>
                <slot name="col-header" v-bind="{ col }" v-if="!!$scopedSlots['col-header']"></slot>
                <span v-else>{{$translate(col.label)}}</span>
            </template>
            <!-- 专为嵌套表格使用 -->
            <slot name="nest-table" v-bind="{ col }"></slot>
            <template v-slot="{ row, $index: rIndex }">
                <template v-if="!col.type">{{row[col.prop]}}</template>
                <template v-else-if="col.type === 'select'">{{dictFormat(row[col.prop], col.optionsKey) | keyMapFormat(col.options)}}</template>
                <slot v-else name="col-append" v-bind="{ col, row, rIndex}"></slot>
            </template>
        </el-table-column>
        <!-- </template> -->
        <el-table-column :label="$translate('lang.E202006152352',{defaultText:'操作'})" fixed="right" :width="actionColWidth" v-if="actionColShow">
            <template v-slot="{ row, $index: rIndex }">
                <template v-for="(e, eIndex) in actions.list">
                    <el-button v-if="e.displayRule(row)" type="text" size="mini" :key="eIndex"
                        @click="actionClick(e.event, row, rIndex)" :loading="row[e.event + 'loading'] || false">{{$translate(e.label)}}</el-button>
                </template>
            </template>
        </el-table-column>
    </el-table>
</template>
<script>
import _ from 'lodash';
import { I18nMixins } from '@/components/mixins/i18n';
import { dictWithUrl } from '../api';
const HEIGHTOFFSET = 210;
export default {
    name: 'tableBox',
    mixins: [I18nMixins],
    inheritAttrs: false,
    components: {},
    props: {
        actions: {
            type: Object,
            default() {
                return {
                    list: []
                };
            },
            validator: function (obj) {
                return !!obj.list;
            }
        },
        columns: {
            type: Array,
            required: true,
            default() {
                return [];
            }
        },
        rows: {
            type: Array,
            required: true,
            default() {
                return [];
            }
        },
        selection: {
            type: Boolean,
            default() {
                return false;
            }
        },
        selectable: {
            type: Function,
            default() {
                return true;
            }
        },
        index: {
            type: Boolean,
            default() {
                return false;
            }
        }
    },
    watch: {
        actions: {
            handler(val) {
                if (val.list) {
                    _.forEach(val.list, ele => {
                        if (!_.isFunction(ele.displayRule)) {
                            ele.displayRule = () => true;
                        }
                    });
                }
            },
            immediate: true
        },
        rows: {
            handler(val) {
                // fix 修正elementui多个tab都包含表格，切换tab时table复选框绝对定位计算错误的问题
                this.$refs.elTable.debouncedUpdateLayout();
                this.newRows = val;
            },
            immediate: false
        },
        activeColumns: {
            handler() {
                // 对newRows进行延迟赋值是为了修正列变化时右fix的操作列top属性计算异常的问题
                if (this.rows.length > 0) {
                    this.newRows = [];
                }
                this.$nextTick(() => {
                    this.newRows = this.rows;
                    this.$refs.elTable.debouncedUpdateLayout();
                });
            },
            immediate: false
        }
    },
    computed: {
        _() {
            return _;
        },
        attrsExt() {
            const result = _.cloneDeep(this.$attrs);
            if (!_.has(result, 'max-height') && !_.has(result, 'height')) {
                result.height = document.body.clientHeight - HEIGHTOFFSET;
            }
            return result;
        },
        actionColShow() {
            return this.actions.list && this.actions.list.length > 0;
        },
        actionColWidth() {
            const btnWidth = 50;
            return this.actions.fixedWidth || btnWidth * this.actions.list.length;
        },
        activeColumns() {
            return this.columns.filter(item => !item.hiddenColumn);
        }
    },
    filters: {
        keyMapFormat(val, options) {
            if (!options) return val;
            // 方案1的处理方式：options保持elementui格式
            // const obj = _.find(options, ['value', val]);
            // return _.get(obj, 'label', val);

            // 方案2的处理方式：options使用简单对象
            return _.get(options, val, val);
        }
    },
    data() {
        return {
            dictFormat: (val) => val,
            newRows: []
        };
    },
    methods: {
        actionClick(event, row, rIndex) {
            this.$emit('action-click', event, row, rIndex);
        },
        getTable() {
            return this.$refs['elTable'];
        },
        rowClassHandler({ row }) {
            return this.getTable().selection.includes(row) ? 'selected-row' : '';
        }
    },
    created() {
        // 表格渲染前先判断表格列中使用到的数据字典是否都在浏览器端有备份，没有的进行接口获取，保证表格的字典转义
        const keys = _.chain(this.columns).map(obj => obj.optionsKey).filter(str => !_.isNil(str)).value();
        if (keys.length > 0) {
            dictWithUrl(keys.join(',')).then(obj => {
                if (obj.code === '0') {
                    const data = obj.data;
                    this.dictFormat = (val, optionsKey) => {
                        if (!optionsKey) return val;
                        const keyMaps = _.get(data, optionsKey);
                        return _.get(keyMaps, val, val);
                    };
                }
            });
        }
    }
};

</script>
<style lang="less">
.table-box {
    .el-date-editor.el-input,
    .el-date-editor.el-input__inner {
        width: 100%;
    }
    .el-table__header{
        thead{
            .cell{
                overflow: hidden;
                text-overflow:ellipsis;
                white-space: nowrap;
            }
        }
    }
}
</style>
