import request from '@/utils/http';
const apiCrmHost = 'api-lcrm';

// 业务类型字典查询
export const getDictDataApi = (params) => {
    return request({
        url: 'api-mdm/dictionaryDetail/dict',
        method: 'get',
        params
    });
};
// 合同查询接口
export const getContractInfoList = (data) => {
    return request({
        url: apiCrmHost + '/contract/info/page', // 非权限控制
        method: 'post',
        data
    });
};
// 合同查询接口
export const getContractInfoAuthList = (data) => {
    return request({
        url: apiCrmHost + '/contract/info/authPage', // 权限控制
        method: 'post',
        data
    });
};

// 合同列表单条数据删除
export const contractDelete = (data) => {
    return request({
        url: apiCrmHost + '/contract/info/remove',
        method: 'DELETE',
        data
    });
};

// 根据合同编码查询详情
export const getByContractCodeApi = (params) => {
    return request({
        url: apiCrmHost + '/contract/info/getByContractCode',
        method: 'get',
        params
    });
};

// 合同列表导出鉴权接口
export const getUserDataAuthsApi = (params) => {
    return request({
        url: apiCrmHost + '/getUserRoleAndDataAuths',
        method: 'get',
        params
    });
};
