<template>
    <el-dialog class="advance-dialog" width="800px"
        :visible.sync="dialogVisible"
        :append-to-body="true"
        @close="dialogClose">
        <div slot="title" class="advance-dialog_title">
            {{$translate(dialogTitle)}}
        </div>
        <div class="component-container">
            <div class="top-search-group">
                <el-form size="mini" :inline="true" :model="searchObj">
                    <slot name="serach-prefix"></slot>
                    <el-form-item>
                        <el-select v-model="searchObj.key" :placeholder="$translate('component.E202007310016', {defaultText:'请选择'})">
                            <el-option v-for="option in selectOptions"
                                :key="option.prop"
                                :label="option.value"
                                :value="option.prop"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <el-input v-model.trim="searchObj.keyword"
                                  :placeholder="$translate('component.E202007310017', {defaultText:'请输入'})"
                                  @keyup.enter.native="search" @change="inputChange">
                        </el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button class="search-btn" type="primary" size="mini" @click="search">
                            <em class="iconfont icon-search_Inquire search-dialog-icon"></em>{{$translate('component.E202006152369', {defaultText:'查询'})}}
                        </el-button>
                        <el-button @click="reset" size="mini"  type="primary" plain>{{$translate('component.E202007310003', {defaultText:'重置'})}}</el-button>
                    </el-form-item>
                    </el-form>
            </div>
            <table-box :selection="false" :rows="tableData" :columns="columns" height="300" v-loading="loading" @current-change="currentRowChange"
                @row-dblclick="rowDbClick" :highlight-current-row="true"></table-box>
            <div class="pager" v-if="noPage">
                <el-pagination
                    layout="total, prev, pager, next"
                    :current-page.sync="page.pageNo"
                    :page-size="page.pageSize"
                    :total="pageTotal"
                    @current-change="currentChange">
                </el-pagination>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button size="mini" @click="dialogVisible = false;">{{$translate('component.E202006152345', {defaultText:'取消'})}}</el-button>
                <el-button type="primary" size="mini" @click="confirm" :disabled="disabledCancel">{{$translate('component.E202006152363', {defaultText:'确定'})}}</el-button>
            </div>
        </div>
    </el-dialog>
</template>
<script>
import request from '@/utils/http';
import _ from 'lodash';
import TableBox from '@/components/lots/tableBox/Index';
import { I18nMixins } from '@/components/mixins/i18n';

// import { filterSpecChar } from '../utils';
export default {
    mixins: [I18nMixins],
    components: {
        TableBox
    },
    props: {
        disabledDbClick: {
            type: Boolean,
            default: false
        },
        rejectClose: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        selectOptions() {
            return this.config.advanceConfig || this.config.tableConfig;
        },
        dialogTitle() {
            return this.config.advanceLabel || this.config.name || this.config.label;
        }
    },
    data() {
        return {
            disabledCancel: false,
            dialogVisible: false,
            requestMethod: 'get',
            loading: false,
            page: {
                pageSize: 10,
                pageNo: 1
            },
            pageTotal: 0,
            searchObj: {},
            tableData: [],
            config: {},
            columns: null,
            noPage: true
        };
    },
    watch: {
        dialogVisible(val) {
            if (!val) {
                this.disabledCancel = false;
            }
        }
    },
    methods: {
        async fetchData() {
            this.loading = true;
            // let params = { ...this.advanceCascade, ...this.page, [this.searchObj.key]: this.searchObj.keyword, tenantCode: this.$store.getters.currentTenant.tenantCode };
            let params = { ...this.advanceCascade, ...this.page, [this.searchObj.key]: this.searchObj.keyword };
            // 添加请求前钩子，用于非标准化基础数据接口对请求参数做数据转换
            const beforeReq = _.get(this.config, 'beforeRequest');
            if (beforeReq && _.isFunction(beforeReq)) {
                params = beforeReq(params);
            }
            const reqConfig = {
                method: this.requestMethod,
                url: `${this.prefix}${this.config.advanceUrl}`
            };
            if (this.requestMethod === 'get') {
                reqConfig.params = params;
            } else {
                reqConfig.data = params;
            }
            let res = await request(reqConfig);
            // 添加响应后钩子，用于非标准化基础数据接口做数据转换
            const afterRes = _.get(this.config, 'afterResponse');
            if (afterRes && _.isFunction(afterRes)) {
                res = afterRes(res);
            }
            if (res && res.code === '0') {
                if (res.data.totalPage !== 0 && this.page.pageNo > res.data.totalPage) {
                    this.page.pageNo = res.data.totalPage;
                    this.fetchData();
                } else {
                    this.tableData = res.data.list;
                    this.pageTotal = res.data.totalCount;
                }
            } else {
                this.tableData = [];
            }
            this.loading = false;
        },
        show(status = true, config, advanceCascade = {}, keyword = '') {
            this.requestMethod = config.method || 'get';
            this.prefix = config.prefix || '/api-mdm/';
            this.dialogVisible = status;
            if (status === false) return;
            if (!config) {
                this.$lcpMessage.error(this.$translate('component.E202007310018', { defaultText: '弹窗配置对象为空！' }));
                return;
            }
            this.config = config;
            this.noPage = _.get(config, 'noPage', true);
            this.columns = config.tableConfig.map(val => {
                return {
                    ...val,
                    label: val.value,
                    prop: val.prop
                };
            });
            this.$set(this.searchObj, 'key', this.selectOptions[0].prop);
            this.$set(this.searchObj, 'keyword', keyword);
            this.advanceCascade = advanceCascade;
            this.fetchData();
        },
        // 页码变化
        currentChange(val) {
            this.page.pageNo = val;
            this.fetchData();
        },
        // 搜索
        search() {
            this.page.pageNo = 1;
            this.fetchData();
        },
        // 重置
        reset() {
            this.searchObj.keyword = '';
            this.fetchData();
        },
        // 表格
        currentRowChange(row) {
            this.currentRow = row;
        },
        // 表格双击
        rowDbClick(row) {
            if (this.disabledDbClick) return false;
            this._emitResult(row);
        },
        confirm() {
            if (!this.currentRow) {
                this.$lcpMessage.warning(this.$translate('component.E202006230017', { defaultText: '没选中数据' }));
                return;
            }
            this._emitResult(this.currentRow);
        },
        // 返回选中值
        _emitResult(row) {
            const data = {};
            if (!this.config.cbParams) {
                this.$lcpMessage.error(this.$translate('component.E202006230018', { defaultText: '弹窗没定义cbParams配置!' }));
                return;
            }
            for (const item of this.config.cbParams) {
                let { 0: sourceProp, 1: destProp } = item.split('#');
                destProp = destProp || sourceProp;
                data[destProp] = row[sourceProp];
            }
            this.$emit('confirm', data);
            if (this.rejectClose) {
                this.disabledCancel = true;
                return false;
            }
            this.dialogVisible = false;
        },
        inputChange(val) { },
        dialogClose() {
            // 重置属性
            this.page = {
                pageSize: 10,
                pageNo: 1
            };
            this.pageTotal = 0;
            this.searchObj = {};
        }
    }
};

</script>

<style lang="less">
.advance-dialog {
    .el-dialog__header {
        border-bottom: 1px solid #E9E9E9;
    }
    .el-dialog__headerbtn .el-dialog__close {
        color: #000;
    }
}
.advance-dialog_title {
    font-size: 14px;
    color: #333333;
    padding: 0 0 5px;
}
.component-container {
    padding: 0 10px;
    .dialog-footer {
        margin-top: 11px;
        margin-bottom: 6px;
        text-align: right;
    }
    .pager {
        text-align: right;
        margin-top: 13px;
        padding-bottom: 13px;
        border-bottom: 1px solid #E9E9E9;
    }
    .search-btn {
        padding: 7px;
    }
    .iconsearch_Inquire {
        font-size: 12px;
        margin-right: 7px;
    }
}

.search-dialog-icon{
    font-size: 12px!important;
}
</style>
