<template>
    <el-dialog :width="dialogWidth"
        :visible.sync="dialogVisible"
        :append-to-body="true"
        @close="dialogCloseAction">
        <div slot="title" class="column-config_title">
            {{dialogTitle}}
        </div>
        <slot name="head"></slot>
        <div class="component-container">
            <div class="top-search-group">
                <el-form :inline="true" :model="searchObj">
                    <div class="search-box-dialog" v-if="config.searchType == 'input'">
                        <el-form-item v-for="item in config.advanceConfig" :key="item.prop">
                            <el-date-picker
                                v-if="item.type === 'date'"
                                v-model="searchObj[item.prop]"
                                :type="item.dateType|| 'daterange'"
                                :format="item.format"
                                :value-format="item.valueFormat"
                                :range-separator="item.separator|| '至'"
                                :start-placeholder="item.startPlaceholder||'开始日期'"
                                :end-placeholder="item.endPlaceholder||'结束日期'"
                                :default-time="item.defaultTime || ['00:00:00', '23:59:59']"
                                >
                            </el-date-picker>
                            <otpAdvance
                                v-else-if="item.type === 'advance'"
                                @getAdvanceValue="getAdvanceValue($event, item)"
                                :mValue="searchObj[item.prop]"
                                :params="item"
                                clearable
                                :disabled="false">
                            </otpAdvance>
                            <lots-select
                                v-else-if="item.type === 'select'"
                                :optionsKey="item.optionsKey ||'' "
                                :placeholder="item.placeholder || `请选择${ item.value }`"
                                v-model="searchObj[item.prop]"
                                clearable
                                style="width: 100%" />
                            <el-input v-else v-model.trim="searchObj[item.prop]" :placeholder="item.value"
                                @keyup.enter="search" clearable></el-input>
                        </el-form-item>
                    </div>
                    <div class="search-box-dialog" v-else>
                        <el-form-item>
                            <el-select v-model="searchObj.key" :popper-append-to-body="false" placeholder="请选择">
                                <el-option v-for="option in selectOptions"
                                    :key="option.prop"
                                    :label="option.value"
                                    :value="option.prop"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item>
                            <el-input v-model.trim="searchObj.keyword" placeholder="请输入" @keyup.enter.native="search" @change="inputChange"></el-input>
                        </el-form-item>
                    </div>
                    <slot name="serach-suffix"></slot>
                    <el-form-item>
                        <el-button type="primary" @click="search">查询</el-button>
                        <el-button @click="reset">重置</el-button>
                    </el-form-item>
                </el-form>
            </div>
            <div class="center-container">
                <el-table
                    :data="tableData"
                    highlight-current-row
                    v-loading="loading"
                    height="300"
                    ref="listTable"
                    @row-click="rowClick"
                    @select="changeSelect"
                    @select-all="changeSelectAll"
                    @current-change="currentRowChange"
                    class="left-table">
                    <el-table-column type="selection" width="55"></el-table-column>
                    <el-table-column
                        v-for="(column, index) in config.tableConfig"
                        :key="column.prop + index"
                        :prop="column.prop"
                        :label="column.value"
                        :width="column.width"
                        show-overflow-tooltip>
                        <template v-slot="{ row }">
                            <template v-if="column.type === 'select'">{{dictFormat(row[column.prop], column.optionsKey,column.options) }}</template>
                            <template v-else>{{row[column.prop]}}</template>
                        </template>
                    </el-table-column>
                </el-table>
                <div class="right-checked-list">
                    <div class="checked-list-title">
                        <span class="checked-num">已选<i>&nbsp;{{checkedList.length}}&nbsp;</i>项</span>
                        <span class="clear-all" @click="clearAll(true)">清空</span>
                    </div>
                    <div class="checked-items">
                        <div v-for="(item, index) in checkedList" :key="index">
                            <div v-if="config.hideTooltip" class="checked-every">
                                    <span class="text">{{displayColName === '' ? Object.values(item)[0] : item[displayColName]}}</span>
                                    <span class="close" @click="clearOne(item, index)"><i class="el-icon-close" style="font-size: 12px;"></i></span>
                                </div>
                            <el-tooltip
                                v-else
                                effect="dark"
                                placement="left">
                                <div slot="content">
                                    <span v-for="(attrItem, attrIndex) in item" :key="attrIndex">
                                        {{attrItem}}<br/>
                                    </span>
                                </div>
                                <div class="checked-every">
                                    <span class="text">{{displayColName === '' ? Object.values(item)[0] : item[displayColName]}}</span>
                                    <span class="close" @click="clearOne(item, index)"><i class="el-icon-close" style="font-size: 12px;"></i></span>
                                </div>
                            </el-tooltip>
                        </div>
                    </div>
                </div>
            </div>
            <div class="config-footer">
                <el-row>
                    <el-col :span="3" style="line-height:30px;">共&nbsp;&nbsp;{{pageTotal}}&nbsp;&nbsp;项</el-col>
                    <el-col :span="16">
                        <el-pagination
                            background
                            layout="sizes, prev, pager, next"
                            :current-page.sync="page.pageNo"
                            :page-size="page.pageSize"
                            :total="pageTotal"
                            :page-sizes="config.pageSizes"
                            @current-change="currentChange"
                            @size-change="pageSizeChange">
                        </el-pagination>
                    </el-col>
                    <el-col :span="5" style="text-align: right;">
                        <el-button type="text" @click="closeDialog">取消</el-button>
                        <el-button type="primary" @click="confirm">确定</el-button>
                    </el-col>
                </el-row>
            </div>
        </div>
    </el-dialog>
</template>
<script>
import Vue from 'vue';
import request from '@/utils/http';
import _ from 'lodash';
import { getDictDataApi } from '@/modules/mdm/api/contractManage/contractListApi.js';
import otpAdvance from '@/components/lots/otpAdvance/Index.vue';
import lotsSelect from '@/components/lots/lotsSelect/Index';
const BASE_SIZE = 10;
const TWO_SIZE = 2;
const THIRD_SIZE = 3;
const FIVE_SIZE = 5;
const DEFAULT_PAGE_SIZES = [BASE_SIZE, TWO_SIZE * BASE_SIZE, THIRD_SIZE * BASE_SIZE, FIVE_SIZE * BASE_SIZE];
export default {
    components: {
        otpAdvance,
        lotsSelect
    },
    computed: {
        selectOptions() { // 可选搜索项
            return this.config.advanceConfig || this.config.tableConfig;
        },
        dialogTitle() { // 弹窗标题
            return this.config.advanceLabel || this.config.name || this.config.label;
        }
    },
    data() {
        return {
            dialogVisible: false, // 弹窗显示开关
            loading: false, // 加载中显示
            defaultPageSizes: DEFAULT_PAGE_SIZES, // 默认pagesizes
            page: {
                pageSize: 10, // 每页条数
                pageNo: 1 // 当前页码
            },
            pageTotal: 0, // 数据总记录数
            searchObj: {}, // 搜索配置
            tableData: [], // 表格数据列表
            config: {}, // 本地化配置
            checkedList: [], // 已选列表
            displayColName: '', // 确定显示在已选列表中的是哪一列的字段值
            requestMethod: 'get', // 接口请求方式
            dialogWidth: '800px',
            optionsData: {} // 数字字典对象
        };
    },
    methods: {
        /**
         * @description: 获取数据列表
        */
        // eslint-disable-next-line complexity
        async fetchData() {
            this.loading = true;
            // const search = this.searchObj.keyword === '' ? {} : { [this.searchObj.key]: encodeURIComponent(this.searchObj.keyword) };
            let params = { ...this.advanceCascade, ...this.page };
            if (this.config.searchType === 'input') {
                params = {
                    ...params,
                    ...this.searchObj
                };
            } else {
                params = {
                    ...params,
                    [this.searchObj.key]: this.searchObj.keyword
                };
            };
            // 添加请求前钩子，用于非标准化基础数据接口对请求参数做数据转换
            const beforeReq = _.get(this.config, 'beforeRequest');
            if (beforeReq && _.isFunction(beforeReq)) {
                params = beforeReq(params);
            }
            const reqConfig = {
                method: this.requestMethod,
                url: `${this.prefix}${this.config.advanceUrl}`
            };
            if (this.requestMethod === 'get') {
                reqConfig.params = params;
            } else {
                reqConfig.data = params;
            }
            let res = null;
            res = await request(reqConfig);
            // 添加响应后钩子，用于非标准化基础数据接口做数据转换
            const afterRes = _.get(this.config, 'afterResponse');
            if (afterRes && _.isFunction(afterRes)) {
                res = afterRes(res);
            }
            if (res && res.code === '0') {
                if (res.data.totalPage !== 0 && this.page.pageNo > res.data.totalPage) {
                    this.page.pageNo = res.data.totalPage;
                    this.fetchData();
                } else {
                    this.tableData = res.data.list;
                    this.pageTotal = res.data.totalCount;
                }
            } else {
                this.tableData = [];
            }
            this.tableSelect();
            this.loading = false;
        },
        async getDictData(config) {
            const dictCodes = [];
            config.tableConfig.forEach(item => {
                if (item.optionsKey) {
                    dictCodes.push(item.optionsKey);
                }
            });
            if (dictCodes.length) {
                await getDictDataApi({ dictCodes: String(dictCodes) }).then(res => {
                    if (+res.code === 0) {
                        this.optionsData = res.data;
                    }
                });
            }
        },
        /**
         * @description: 初始化显示弹窗
         * @param {boolean} status 打开弹窗还是关闭弹窗
         * @param {object} config 高级弹窗的配置项对象
         * @param {object} advanceCascade 级联参数（ 弹窗表格查询时候，添加查询参数）
         * @param {array} checkedList 设置选中数组
         * @return {null} 弹窗配置对象为空 或 设置status关闭弹窗，则停止执行下面操作
         */
        async show(status = true, config, advanceCascade = {}, checkedList) {
            await this.getDictData(config);
            config.tableConfig.forEach((item) => {
                if (item.displayName) this.displayColName = item.prop;
            });
            config.pageSizes = _.isArray(config.pageSizes) ? config.pageSizes : this.defaultPageSizes;
            this.page.pageSize = config.pageSizes[0] || BASE_SIZE;
            this.dialogWidth = config.dialogWidth || '800px';
            this.requestMethod = config.method || 'get';
            this.prefix = config.prefix || '/api-lcp-idms/';
            this.dialogVisible = status;
            if (status === false) return;
            if (!config) {
                this.$message.error('弹窗配置对象为空！');
                return;
            }
            this.config = config;
            if (config.searchType === 'input') {
                config.advanceConfig.forEach(item => {
                    Vue.set(this.searchObj, item.prop, item.defaultValue || '');
                    // this.searchObj[item.prop] = item.defaultValue || '';
                    if (config.searchObj) {
                        Vue.set(this.searchObj, config.searchObj.key, config.searchObj.keyword);
                    }
                });
            } else {
                this.$set(this.searchObj, 'key', this.selectOptions[0].prop);
                this.$set(this.searchObj, 'keyword', '');
            }

            this.advanceCascade = advanceCascade;
            if (checkedList) this.checkedList = JSON.parse(JSON.stringify(checkedList));
            this.fetchData();
        },
        /**
         * @description: 判断表格的对象与选中列表对象是否相等
         * @param {array} array 需要操作比较的数组
         * @param {object} theItem 需要比较的单个数据项对象
         * @param {function} callback 判断完成后的回调函数
         */
        judgeEqual(array, theItem, callback) {
            array.some((item, index) => {
                const length = Object.entries(item).length;
                let count = 0;
                for (const [key, value] of Object.entries(item)) {
                    if (theItem[key] === value) {
                        count++;
                    }
                }
                if (count === length || JSON.stringify(item) === JSON.stringify(theItem)) {
                    callback && typeof callback === 'function' && callback(item, index);
                    return true;
                }
            });
        },
        /**
         * @description: 对表格进行选择操作
         */
        tableSelect () {
            if (this.config.initToggletProp) { // 处理后端返回的数据不全，导致无法反选表格
                this.checkedList = _.map(this.checkedList, it => {
                    const current = this.tableData.find(el => el[this.config.initToggletProp] === it[this.config.initToggletProp]);
                    return current || it;
                });
            }

            this.tableData.forEach((item) => {
                this.judgeEqual(this.checkedList, item, () => {
                    this.$nextTick(() => {
                        this.$refs.listTable.toggleRowSelection(item, true);
                    });
                });
            });
        },
        /**
         * @description: 单选操作
         * @param {array} data 当前选中的数据数组
         * @param {object} row 当前操作行对象
         */
        changeSelect(data, row) {
            const inOrDel = data.indexOf(row);
            if (inOrDel < 0) {
                this.judgeEqual(this.checkedList, row, (cItem, cIndex) => {
                    this.clearOne(cItem, cIndex);
                });
            } else {
                let flag = true;
                this.judgeEqual(this.checkedList, row, () => {
                    flag = false;
                });
                if (flag) this.checkedList.push(row);
            }
        },
        /**
         * @description: 全选操作
         * @param {array} data 当前选中的数据数组
         */
        changeSelectAll(data) {
            if (data.length > 0) {
                data.forEach((item) => {
                    this.changeSelect(data, item);
                });
            } else {
                this.tableData.forEach((item) => {
                    this.judgeEqual(this.checkedList, item, (cItem, cIndex) => {
                        this.clearOne(cItem, cIndex);
                    });
                });
            }
        },
        /**
         * @description: 清除所有已选（可供外部调用）
         * @param {boolean} clearTable 是否需要清除表格（只有内部调用时需要true）
         */
        clearAll(clearTable) {
            this.checkedList = [];
            if (clearTable) this.$refs.listTable.clearSelection();
        },
        /**
         * @description: 清除单个已选
         * @param {object} cItem 当前操作行对象
         * @param {number} cIndex 当前操作行索引
         */
        clearOne(cItem, cIndex) {
            this.checkedList.splice(cIndex, 1);
            this.judgeEqual(this.tableData, cItem, (item, index) => {
                this.$refs.listTable.toggleRowSelection(item, false);
            });
        },
        /**
         * @description: 点击行操作
         * @param {object} row 当前操作行对象
         */
        rowClick(row) {
            this.$refs.listTable.toggleRowSelection(row, true);
            this.changeSelect(this.tableData, row);
        },
        /**
         * @description: 页码变化
         * @param {number} val 页码数字
         */
        currentChange(val) {
            this.page.pageNo = val;
            this.fetchData();
        },
        /**
         * @description: 每页条数变化
         * @param {number} val 每页条数
         */
        pageSizeChange(val) {
            this.page.pageSize = val;
            this.fetchData();
        },
        /**
         * @description: 搜索按钮操作
         */
        search() {
            this.page.pageNo = 1;
            this.fetchData();
        },
        /**
         * @description: 重置按钮操作
         */
        reset () {
            if (this.config.searchType === 'input') {
                this.config.advanceConfig.forEach(item => {
                    this.searchObj[item.prop] = item.defaultValue || '';
                    if (item.cbParams) {
                        for (const it of item.cbParams) {
                            let { 0: sourceProp, 1: destProp } = it.split('#');
                            destProp = destProp || sourceProp;
                            Vue.set(this.searchObj, destProp, '');
                        }
                    }
                });
            } else {
                this.searchObj.keyword = '';
            }
            this.fetchData();
            this.$emit('reset', this.searchObj.keyword);
        },
        /**
         * @description: 表格当前行操作
         * @param {object} row 当前操作行对象
         */
        currentRowChange(row) {
            this.currentRow = row;
        },
        /**
         * @description: 确认按钮操作
         * @return {null} 没选中取消执行下面操作
         */
        confirm() {
            const { noMinNumLimit, maxNum } = this.config;
            if (!noMinNumLimit && !this.checkedList.length) {
                this.$message.warning('没选中数据');
                return;
            }
            if (maxNum && this.checkedList.length > maxNum) {
                this.$message.warning(`${this.config.name}最多选中${maxNum}条`);
                return;
            }
            this._emitResult();
        },
        /**
         * @description: 返回选中值
         * @return {null} 弹窗没定义cbParams，执行下面操作
         */
        _emitResult() {
            const data = [];
            if (!this.config.cbParams) {
                this.$message.error('弹窗没定义cbParams配置!');
                return;
            }
            this.checkedList.forEach((checkedItem, index) => {
                data[index] = {};
                for (const item of this.config.cbParams) {
                    let { 0: sourceProp, 1: destProp } = item.split('#');
                    destProp = destProp || sourceProp;
                    data[index][destProp] = checkedItem[sourceProp];
                }
            });
            this.$emit('confirm', data);
            this.$emit('getCheckedList', JSON.parse(JSON.stringify(this.checkedList)));
            this.closeDialog();
        },
        /**
         * @description: 关闭弹窗
         */
        closeDialog() {
            this.dialogCloseAction();
            this.dialogVisible = false;
        },
        inputChange(val) { },
        /**
         * @description: 页面关闭重置参数操作
         */
        dialogCloseAction() {
            this.checkedList = [];
            this.page = {
                pageSize: 10,
                pageNo: 1
            };
            this.pageTotal = 0;
            this.searchObj = {};
            this.tableData = [];
        },
        dictFormat(val, optionsKey, options) {
            if (optionsKey) {
                const keyMaps = _.get(this.optionsData, optionsKey);
                const optionsKeyValue = _.get(keyMaps, val, val);
                return optionsKeyValue;
            } else if (options) {
                return _.get(options, val, val);
            } else {
                return val;
            }
        },
        // 待办模板组件名称回调
        getAdvanceValue (val, config) {
            for (const item of config.cbParams) {
                let { 0: sourceProp, 1: destProp } = item.split('#');
                destProp = destProp || sourceProp;
                Vue.set(this.searchObj, destProp, val[destProp]);
            }
        }
    }
};

</script>

<style lang="less">
.column-config_title{
    small{
        margin-left: 25px;
    }
}
.component-container {
    .center-container{
        width: 100%;
        height: 300px;
        display: flex;
        .left-table {
            flex: 1;
            float: left;
            box-sizing: border-box;
        }
        .right-checked-list {
            width: 200px;
            margin: 0 0 0 10px;
            height: 100%;
            float: left;
            box-sizing: border-box;
            .checked-num i {
                font-style: normal;
                color: #3EA4EB;
            }
            .clear-all {
                float: right;
                cursor: pointer;
            }
            .checked-list-title {
                width: 100%;
                height: 38px;
                background-color: #F2F2F2;
                line-height: 38px;
                color: #666666;
                padding: 0 10px 0 20px;
                box-sizing: border-box;
            }
            .checked-items {
                width: 100%;
                overflow: auto;
                height: 262px;
            }
            .checked-every {
                width: 100%;
                height: 38px;
                line-height: 38px;
                color: #666666;
                padding: 0 10px 0 20px;
                box-sizing: border-box;
                &:hover {
                    background-color: #F0F8FA;
                }
                .text {
                    width: 145px;
                    float: left;
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                }
                .close {
                    float: right;
                    cursor: pointer;
                    color: #AAAAAA;
                    font-size: 18px;
                }
            }
        }
        &:after {
            clear: both;
        }
    }
    .config-footer {
        margin-top: 18px;
    }
}
.search-box-dialog{
    display: inline-block;
}
</style>
