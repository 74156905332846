<template>
  <el-button v-if="isShow" v-bind="$attrs" v-on="listenersExt">
      <slot></slot>
  </el-button>
</template>
<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';
/**
 * @module lots/lotsButton
 * @desc 按钮组件的二次封装
 * @vue-prop {String} code - 控制按钮权限用，按钮的resourceCode
 * @vue-prop {Number} [delay=500] - 按钮防抖延时
 * @vue-data {Object} listenersExt - 存放处理过防抖的点击事件
 * @vue-computed {Array.<String>} btnPermissions - 该租户的按钮权限数组
 * @vue-computed {Boolean} isShow - 根据code属性计算按钮的显示隐藏
 */
export default {
    name: 'lotsButton',
    inheritAttrs: false,
    props: ['code', 'delay'],
    data() {
        return {
            listenersExt: {}
        };
    },
    computed: {
        ...mapGetters(['btnPermissions']),
        isShow() {
            if (!this.code) return true;
            return _.includes(this.btnPermissions, this.code);
        }
    },
    mounted() {
        const $listenersCopy = _.clone(this.$listeners);
        const clickEvent = _.get($listenersCopy, 'click');
        if (clickEvent) {
            const delay = 500;
            const options = { 'leading': true, 'trailing': false };
            $listenersCopy.click = _.debounce(clickEvent, +this.delay || delay, options);
        }
        this.listenersExt = $listenersCopy;
    }
};
</script>
